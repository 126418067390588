
//localhost

//export const  RESOURCE_NAME = 'https://localhost:44329';
//export const cloudfrontURL = "https://d2f8qa94ghykcl.cloudfront.net";
//export const bookingCourtsURL = "https://localhost:44328/bookcourts";
//export const imgixURL = "https://test-suubz.imgix.net";
//export const STRIPE_PUBLISHABLE_KEY = "pk_test_51HkTptDdHbxiwNiyPA4ESNiYudWCWGKMF8oymjl5WWcDckNGApkCQNDsglI3WJg1NXiPXaU0xiLq4dMTcN2hBx0F00dQNxrlus";
//export const enableGTag = false; // Vue.js + Google Analytics
///*const ga_TrackingId = "UA-193356017-2";       //test (GA3)*/
//export const ga4_MeasurementId = "G-H61LQJ4SXB";       //test (GA3 + GA4)
//export const enableFacebookPixel = false; // Facebook Pixel
//export const fbp_pixelId = 1889837831182773;
//export const facebookAppId = 672268487063756;

//test

 export const RESOURCE_NAME = 'https://api-test.suubz.com';
 export const cloudfrontURL = "https://d2f8qa94ghykcl.cloudfront.net";
 export const bookingCourtsURL = "http://squash-blazor-test.s9va5fv9vn.us-east-1.elasticbeanstalk.com/bookcourts";
 export const imgixURL = "https://test-suubz.imgix.net";
 export const STRIPE_PUBLISHABLE_KEY = "pk_test_51HkTptDdHbxiwNiyPA4ESNiYudWCWGKMF8oymjl5WWcDckNGApkCQNDsglI3WJg1NXiPXaU0xiLq4dMTcN2hBx0F00dQNxrlus";
 export const enableGTag = true; // Vue.js + Google Analytics
 /*const ga_TrackingId = "UA-193356017-2";       //test (GA3)*/
 export const ga4_MeasurementId = "G-H61LQJ4SXB";       //test (GA3 + GA4)
 export const enableFacebookPixel = true; // Facebook Pixel
 export const fbp_pixelId = 1889837831182773;
 export const facebookAppId = 672268487063756;

//prod

//export const  RESOURCE_NAME = 'https://api.suubz.com';
//export const cloudfrontURL = "https://d2f8qa94ghykcl.cloudfront.net";
//export const bookingCourtsURL = "https://booking.cphsquash.dk/bookcourts";
//export const imgixURL = "https://suubz.imgix.net";
//export const STRIPE_PUBLISHABLE_KEY = "pk_live_51HkTptDdHbxiwNiylQgmV8i5Vx4W3PZ3rNuItd4ZIEMlFEB9dsKhleyxaFz8K5uh6pMQV3z7an3iYxjb39EQAqsx00fMi0eBdi";
//export const enableGTag = true; // Vue.js + Google Analytics 
///*export const ga_TrackingId = "UA-193356017-1";     //prod (GA3)*/
//export const ga4_MeasurementId = "G-053SV6CDLE";     //prod (GA3 + GA4)
//export const enableFacebookPixel = true;
//export const fbp_pixelId = 1889837831182773;
//export const facebookAppId = 351158199529896;